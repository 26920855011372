<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form v-if="serviceProfile._id">
        <b-card>
          <b-card-title
            ><h2>{{ $t("ServiceForm.Edit_Service") }}</h2>
          </b-card-title>
          <hr />
          <!-- <br /> -->
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="Edit3Icon" />
                <span>{{ $t("dashboard.content") }}</span>
              </template>
              <br />
              <b-row>
                <!--  name-->
                <b-col md="6">
                  <b-form-group>
                    <label class="required"
                      >{{ $t("ServiceForm.Service_name") }}
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Service name"
                    >
                      <b-form-input
                        v-model="serviceProfile.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ServiceForm.Service_name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="owner_type"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="owner_type"
                        >{{ $t("ServiceForm.provided_by") }}
                      </label>

                      <v-select
                        id="State"
                        v-model="serviceProfile.typeofOwner"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createServiceProfile.OwnerTypeOptionArabic
                            : createServiceProfile.OwnerTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Service country"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="Country"
                        >{{ $t("ServiceForm.Service_country") }}
                      </label>

                      <v-select
                        id="Country"
                        v-model="serviceProfile.profileCountry"
                        :options="countries"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="
                          retreiveStates(serviceProfile.profileCountry.value)
                        "
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Service state"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="State"
                        >{{ $t("ServiceForm.Service_state") }}
                      </label>

                      <v-select
                        id="State"
                        v-model="serviceProfile.profileState"
                        :options="statesOptions"
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Service city"
                      rules="required"
                    >
                      <label class="required" label-for="City"
                        >{{ $t("ServiceForm.Service_city") }}
                      </label>

                      <b-form-input
                        id="City"
                        v-model="serviceProfile.address.profileCity"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ServiceForm.Service_city')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="highlights"
                      :rules="`required|max:${$maxHighlightsProfile}`"
                    >
                      <label class="required" label-for="highlights"
                        >{{ $t("ServiceForm.Brief_overview") }}
                      </label>

                      <b-form-textarea
                        id="highlights"
                        v-model="serviceProfile.highlights"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ServiceForm.Brief_overview')"
                        rows="2"
                        max-rows="2"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="service provider"
                      rules="required"
                    >
                      <label class="required" label-for="Owner-Details"
                        >{{ $t("ServiceForm.description_provider") }}
                      </label>
                      <b-form-textarea
                        id="Owner-Details"
                        v-model="serviceProfile.detailsofOwner"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ServiceForm.description_provider')"
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label class="required" label-for="description"
                      >{{ $t("ServiceForm.description") }}
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-textarea
                        id="description"
                        v-model="serviceProfile.description"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ServiceForm.description')"
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Industry"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="Industry"
                        >{{ $t("ServiceForm.industries_belong") }}
                      </label>
                      <div id="app">
                        <treeselect
                          v-model="serviceProfile.profileIndustries"
                          :multiple="true"
                          :placeholder="$t('common.Select')"
                          :options="industries"
                          @input="handeTreeSelect"
                          :normalizer="
                            $store.state.locale.locale == 'ar'
                              ? normalizerArabic
                              : normalizer
                          "
                        />
                      </div>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="Category"
                        >{{ $t("ServiceForm.needs_from_sharing") }}
                      </label>
                      <v-select
                        id="Category"
                        v-model="serviceProfile.category"
                        :options="categories"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="resetDataSaleType()"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  md="6"
                  v-if="
                    serviceProfile.category.value == '62b40b53956d948f90a3940c'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sale type"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="saleType"
                        >{{ $t("ServiceForm.sell_service") }}
                      </label>

                      <v-select
                        id="saleType"
                        v-model="serviceProfile.saleType"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createServiceProfile.SaleTypeOptionArabic
                            : createServiceProfile.SaleTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  md="6"
                  v-if="
                    serviceProfile.saleType &&
                    serviceProfile.saleType.value == '1' &&
                    serviceProfile.category.value == '62b40b53956d948f90a3940c'
                  "
                >
                  <b-row>
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Currency"
                        rules="required"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <label class="required" label-for=""
                            >{{ $t("ServiceForm.Currency") }}
                          </label>
                          <v-select
                            id=""
                            v-model="serviceProfile.currencyId"
                            :options="currencies"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="8">
                      <validation-provider
                        #default="{ errors }"
                        name="Price"
                        rules="required|integer"
                      >
                        <b-form-group
                          label=""
                          :state="errors.length > 0 ? false : null"
                        >
                          <label class="required" label-for="Price">{{
                            $t("ServiceForm.Price")
                          }}</label>

                          <b-form-input
                            id="Price"
                            v-model="serviceProfile.priceNumber"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('ServiceForm.Your_Own_Price')"
                            type="number"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Service type"
                    rules=""
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="servicing_location">{{
                        $t("ServiceForm.Service_type")
                      }}</label>
                      <v-select
                        id="servicing_location"
                        v-model="serviceProfile.serviceLocation"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createServiceProfile.servicingLocationOptionArabic
                            : createServiceProfile.servicingLocationOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="blockchain exchange"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label for="blockchain_exchange" class="required">{{
                        $t("ServiceForm.blockchain_exchange")
                      }}</label>
                      <v-select
                        id="blockchain_exchange"
                        v-model="serviceProfile.blockchainExchange"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createServiceProfile.ExchangeOptionArabic
                            : createServiceProfile.ExchangeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Duration of service"
                    rules=""
                  >
                    <b-form-group
                      label=""
                      label-for=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <label class="" label-for="Service-Type">{{
                        $t("ServiceForm.Duration_service")
                      }}</label>
                      <v-select
                        id="Service-Type"
                        v-model="serviceProfile.serviceType"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createServiceProfile.serviceTypeOptionArabic
                            : createServiceProfile.serviceTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ServiceForm.Facebook')"
                    label-for="facebook"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Facebook"
                      rules="url"
                    >
                      <b-form-input
                        id="facebook"
                        v-model="serviceProfile.facebook"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://facebook.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ServiceForm.Instagram')"
                    label-for="Instagram"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Instagram"
                      rules="url"
                    >
                      <b-form-input
                        id="Instagram"
                        v-model="serviceProfile.twitter"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://Instagram.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ServiceForm.LinkedIn')"
                    label-for="linked-in"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="LinkedIn"
                      rules="url"
                    >
                      <b-form-input
                        id="linked-in"
                        v-model="serviceProfile.linkedIn"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://linkedin.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ServiceForm.Website')"
                    label-for="Other"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Other"
                      rules="url"
                    >
                      <b-form-input
                        id="Other"
                        v-model="serviceProfile.otherURLS"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://other.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-icon"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="required" for="idea-icon">{{
                        $t("ServiceForm.main_image")
                      }}</label>
                      <b-form-file
                        v-model="createServiceProfile.icon"
                        :state="errors.length > 0 ? false : null"
                        accept="image/jpeg, image/png, image/gif"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-images"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-images">{{
                        $t("ServiceForm.service_images")
                      }}</label>
                      <b-form-file
                        v-model="createServiceProfile.images"
                        accept="image/jpeg, image/png, image/gif"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                        :multiple="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-documents"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-documents">{{
                        $t("ServiceForm.service_documents")
                      }}</label>
                      <b-form-file
                        v-model="createServiceProfile.docouments"
                        accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        required
                        :state="errors.length > 0 ? false : null"
                        multiple
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="">
                      <b-form-checkbox
                        id="isSold"
                        v-model="serviceProfile.isSold"
                        name="isSold"
                      >
                        {{ $t("ServiceForm.isSold") }}
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit button -->
                <b-col cols="12">
                  <b-alert
                    v-if="errorMessage"
                    show
                    variant="warning"
                    class="warning"
                    style="color: red"
                    >{{ errorMessage }}</b-alert
                  >
                  <br />
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                    class="mb-2"
                  >
                    {{ $t("common.Edit") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="FileIcon" />
                <span>{{ $t("dashboard.files") }}</span>
              </template>
              <br />
              <b-row>
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    :items="serviceProfile.files"
                    :fields="
                      $store.state.locale.locale == 'ar' ? fieldsArabic : fields
                    "
                  >
                    <template #cell(assets)="data">
                      <div v-viewer class="images">
                        <b-avatar
                          v-if="data.item.type.includes('image')"
                          variant="primary"
                          :src="data.item.fileUrl"
                          text="BV"
                        />
                      </div>
                      <a
                        v-if="data.item.type.includes('application')"
                        :href="data.item.fileUrl"
                        target="_blank"
                        >{{ $t("dashboard.Preview") }}</a
                      >
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        small
                        variant="danger"
                        @click="deleteFileById(data.item._id)"
                      >
                        {{ $t("dashboard.Delete") }}
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- <hr />
          <br /> -->
        </b-card>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import axios from "axios";

import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vue2Dropzone from "vue2-dropzone";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BPopover,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BFormFile,
  BTable,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BFormFile,
    BTable,
    BAvatar,
    VueViewer,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    // VuePhoneNumberInput,
    ToastificationContent,
    BAlert,
    BOverlay,
    BFormCheckbox,
    BTabs,
    BTab,
  },
  data() {
    const url = window.location.href;

    const profileId = url.split("/").slice(-1)[0];

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const minDate = new Date(today);
    // maxDate.setMonth(maxDate.getDay() + 1);
    // maxDate.setDate(15);
    return {
      show: false,
      isSold: false,
      boxTwo: "",
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "assets",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      fieldsArabic: [
        {
          label: "اسم الملف",
          key: "name",
          sortable: true,
        },
        {
          label: "الملف",
          key: "assets",
          sortable: false,
        },
        {
          label: "الإجراء",
          key: "actions",
          sortable: false,
        },
      ],

      indusArrNew: [],
      profileId: profileId,
      errorMessage: "",
      isValidNumber: null,
      dropzoneOptionsIcon: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 5,

        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsDocouments: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        maxFiles: 5,

        headers: { "My-Awesome-Header": "header value" },
      },
      createServiceProfile: {
        selectedState: null,
        name: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        servicingLocation: "",
        serviceType: "",
        selectedCountry: null,
        selectedIndustry: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",

        minDate: minDate,
        icon: null,
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: "",
        // phoneCode: "",
        // email: "",
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],

        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],

        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],

        serviceTypeOption: [
          { value: "contract", text: "Contract" },
          { value: "hourly", text: "Hourly" },
          { value: "daily", text: "daily" },
          { value: "weekly", text: "Wekly" },
          { value: "monthly", text: "Monthly" },
          { value: "yearly", text: "Yearly" },
        ],
        serviceTypeOptionArabic: [
          { value: "contract", text: "عقد" },
          { value: "hourly", text: "بالساعة" },
          { value: "daily", text: "يومياً" },
          { value: "weekly", text: "أسبوعياً" },
          { value: "monthly", text: "شهرياً" },
          { value: "yearly", text: "سنوياً" },
        ],
        servicingLocationOption: [
          { value: "online", text: "Online" },
          { value: "offline", text: "Offline" },
        ],
        servicingLocationOptionArabic: [
          { value: "online", text: "أون لاين" },
          { value: "offline", text: "أوف لاين" },
        ],
      },
      serviceProfile: {
        name: "",
        typeofOwner: "",
        description: "",
        highlights: "",
        detailsofOwner: "",
        userId: {
          username: "",
        },
        address: {
          profileCity: "",
          profileState: "",
          profileCountry: {
            name: "",
          },
        },
        socialMedia: {
          facebook: "",
          linkedIn: "",
          twitter: "",
          otherURLS: "",
        },
        blockchainExchange: null,
        price: {
          priceNumber: "",
          currencyId: {
            name: "",
          },
        },
        category: {
          name: "",
        },
        saleType: "",
        profileIndustries: null,
        profileIndustriesArray: [],
        rating: "4",
        totalComments: "",
        service: {
          serviceType: "",
          serviceLocation: "",
        },
        isSold: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getServiceCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
      // serviceProfile: "profile/getServiceProfile",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",

      editService: "profile/editService",
      // retreiveServiceProfile: "profile/retreiveServiceProfile",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
      deleteFile: "profile/deleteFile",
    }),
    resetDataSaleType() {
      // this.serviceProfile.saleType = "";
      this.serviceProfile.currencyId = "";
      this.serviceProfile.priceNumber = "";
    },
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.serviceProfile.profileIndustries.length = 6;
      }
    },

    filterCountry(countryId) {
      var valObj = this.countries.filter(function (elem) {
        if (elem.value == countryId) {
          console.log(elem.text);
          return elem.text;
        }
      });
    },

    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const profileData = new FormData();
          // const profileserviceData = {};
          // profileserviceData.profileId = this.serviceProfile._id;
          profileData.append("profileId", this.serviceProfile._id);
          profileData.append("name", this.serviceProfile.name);
          profileData.append(
            "typeofOwner",
            this.serviceProfile.typeofOwner.value
          );
          profileData.append(
            "profileCountry",
            this.serviceProfile.profileCountry.value
          );
          profileData.append(
            "profileState",
            this.serviceProfile.profileState.value
          );
          profileData.append(
            "profileCity",
            this.serviceProfile.address.profileCity
          );

          profileData.append("description", this.serviceProfile.description);
          profileData.append(
            "detailsofOwner",
            this.serviceProfile.detailsofOwner
          );
          profileData.append("highlights", this.serviceProfile.highlights);

          profileData.append(
            "profileIndustries",
            JSON.stringify(this.serviceProfile.profileIndustries)
          );

          if (this.serviceProfile.facebook) {
            profileData.append("facebook", this.serviceProfile.facebook);
          }
          if (this.serviceProfile.twitter) {
            profileData.append("twitter", this.serviceProfile.twitter);
          }
          if (this.serviceProfile.linkedIn) {
            profileData.append("linkedIn", this.serviceProfile.linkedIn);
          }
          if (this.serviceProfile.otherURLS) {
            profileData.append("otherURLS", this.serviceProfile.otherURLS);
          }
          profileData.append(
            "blockchainExchange",
            this.serviceProfile.blockchainExchange.value
          );
          profileData.append("category", this.serviceProfile.category.value);
          if (
            this.serviceProfile.saleType &&
            this.serviceProfile.category.value == "62b40b53956d948f90a3940c"
          ) {
            profileData.append("saleType", this.serviceProfile.saleType.value);
            if (this.serviceProfile.saleType.value == "1") {
              profileData.append(
                "currencyId",
                this.serviceProfile.currencyId.value
              );
              profileData.append(
                "priceNumber",
                this.serviceProfile.priceNumber
              );
            }
          }

          if (this.serviceProfile.serviceType) {
            profileData.append(
              "serviceType",
              this.serviceProfile.serviceType.value
            );
          }

          profileData.append(
            "serviceLocation",
            this.serviceProfile.serviceLocation.value
          );
          profileData.append("isSold", this.serviceProfile.isSold);

          if (this.createServiceProfile.icon) {
            profileData.append("profileIcon", this.createServiceProfile.icon);
          }

          if (this.createServiceProfile.images) {
            for (const i of Object.keys(this.createServiceProfile.images)) {
              profileData.append(
                "profileImages",
                this.createServiceProfile.images[i]
              );
            }
          }
          if (this.createServiceProfile.docouments) {
            for (const i of Object.keys(this.createServiceProfile.docouments)) {
              profileData.append(
                "profileDocuments",
                this.createServiceProfile.docouments[i]
              );
            }
          }

          profileData.append("profileType", "service");
          // eslint-disable-next-line no-underscore-dangle
          profileData.append("userId", this.user._id);

          // profileserviceData.profileData = profileData;
          this.editService(profileData)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Modified_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.show = false;
              this.$router.push({ name: "service-listAll" });
            })
            .catch((error) => {
              this.show = false;
              this.errorMessage = error.response.data.error.message;
            });
        }
      });
    },
    getServiceProfile() {
      this.serviceProfile.typeofOwner = {
        value:
          this.serviceProfile.typeofOwner === "individual"
            ? "individual"
            : "group",
        text:
          this.serviceProfile.typeofOwner === "individual"
            ? this.$store.state.locale.locale == "ar"
              ? "فرد"
              : "Individual"
            : this.$store.state.locale.locale == "ar"
            ? "مجموعة"
            : "Group",
      };

      if (this.serviceProfile.socialMedia) {
        this.serviceProfile.facebook = this.serviceProfile.socialMedia.facebook;
        this.serviceProfile.linkedIn = this.serviceProfile.socialMedia.linkedIn;
        this.serviceProfile.twitter = this.serviceProfile.socialMedia.twitter;
        this.serviceProfile.otherURLS =
          this.serviceProfile.socialMedia.otherURLS;
      }

      if (this.serviceProfile.address.profileCountry._id) {
        this.serviceProfile.profileCountry = {
          value: this.serviceProfile.address.profileCountry._id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.serviceProfile.address.profileCountry.arabicName
              : this.serviceProfile.address.profileCountry.name,
        };
        this.serviceProfile.profileState = JSON.parse(
          this.serviceProfile.address.profileState
        );
        this.serviceProfile.profileState = {
          value: this.serviceProfile.profileState.id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.serviceProfile.profileState.arabicName != undefined
                ? this.serviceProfile.profileState.arabicName
                : this.serviceProfile.profileState.name
              : this.serviceProfile.profileState.name,
        };
        this.serviceProfile.profileCity
          ? this.serviceProfile.address.profileCity
          : "";
      } else {
        this.serviceProfile.profileCountry = {
          value: "",
          text: "",
        };
      }

      this.serviceProfile.highlights ? this.serviceProfile.highlights : "";
      this.serviceProfile.detailsofOwner
        ? this.serviceProfile.detailsofOwner
        : "";
      this.serviceProfile.description ? this.serviceProfile.description : "";

      this.serviceProfile.category = {
        value: this.serviceProfile.category.value,
        text:
          this.$store.state.locale.locale == "ar"
            ? this.serviceProfile.category.arabicName
            : this.serviceProfile.category.text,
      };

      this.serviceProfile.blockchainExchange = {
        value:
          this.serviceProfile.blockchainExchange == true ? "true" : "false",
        text:
          this.serviceProfile.blockchainExchange == true
            ? this.$store.state.locale.locale == "ar"
              ? "نعم"
              : "Yes"
            : this.$store.state.locale.locale == "ar"
            ? "لا"
            : "No",
      };

      if (this.serviceProfile.service.serviceType) {
        if (this.serviceProfile.service.serviceType === "contract") {
          this.serviceProfile.serviceType = {
            value: this.serviceProfile.service.serviceType,
            text: this.$store.state.locale.locale == "ar" ? "عقد" : "Contract",
          };
        } else if (this.serviceProfile.service.serviceType === "hourly") {
          this.serviceProfile.serviceType = {
            value: this.serviceProfile.service.serviceType,
            text:
              this.$store.state.locale.locale == "ar" ? "بالساعة" : "Hourly",
          };
        } else if (this.serviceProfile.service.serviceType === "daily") {
          this.serviceProfile.serviceType = {
            value: this.serviceProfile.service.serviceType,
            text: this.$store.state.locale.locale == "ar" ? "يومياً" : "Daily",
          };
        } else if (this.serviceProfile.service.serviceType === "weekly") {
          this.serviceProfile.serviceType = {
            value: this.serviceProfile.service.serviceType,
            text:
              this.$store.state.locale.locale == "ar" ? "أسبوعياً" : "Weekly",
          };
        } else if (this.serviceProfile.service.serviceType === "monthly") {
          this.serviceProfile.serviceType = {
            value: this.serviceProfile.service.serviceType,
            text:
              this.$store.state.locale.locale == "ar" ? "شهرياً" : "Monthly",
          };
        } else if (this.serviceProfile.service.serviceType === "yearly") {
          this.serviceProfile.serviceType = {
            value: this.serviceProfile.service.serviceType,
            text: this.$store.state.locale.locale == "ar" ? "سنوياً" : "Yearly",
          };
        } else {
          this.serviceProfile.serviceType = {
            value: "",
            text: "",
          };
        }
      }
      if (this.serviceProfile.service.serviceLocation) {
        if (this.serviceProfile.service.serviceLocation === "offline") {
          this.serviceProfile.serviceLocation = {
            value: this.serviceProfile.service.serviceLocation,
            text:
              this.$store.state.locale.locale == "ar" ? "أوف لاين" : "Offline",
          };
        } else if (this.serviceProfile.service.serviceLocation === "online") {
          this.serviceProfile.serviceLocation = {
            value: this.serviceProfile.service.serviceLocation,
            text:
              this.$store.state.locale.locale == "ar" ? "أون لاين" : "Online",
          };
        } else {
          this.serviceProfile.serviceLocation = {
            value: "",
            text: "",
          };
        }
      }
      if (this.serviceProfile.saleType) {
        if (this.serviceProfile.saleType === "1") {
          this.serviceProfile.saleType = {
            value: this.serviceProfile.saleType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "سعري الخاص"
                : "My Own Price",
          };
          // if (this.serviceProfile.saleType.value === "1") {
          this.serviceProfile.priceNumber = this.serviceProfile.price
            .priceNumber
            ? this.serviceProfile.price.priceNumber
            : "";

          this.serviceProfile.currencyId = {
            value: this.serviceProfile.price.currencyId._id,
            text: this.serviceProfile.price.currencyId.text,
          };
          // }
        } else if (this.serviceProfile.saleType === "2") {
          this.serviceProfile.saleType = {
            value: this.serviceProfile.saleType,
            text: this.$store.state.locale.locale == "ar" ? "مزاد" : "Auction",
          };
        } else {
          this.serviceProfile.saleType = {
            value: "",
            text: "",
          };
        }
      }

      // this.ServiceForm.isSold != undefined ? false : this.ServiceForm.isSold;

      // return serviceProfile;
    },
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    deleteFileById(id) {
      const deleteData = new FormData();
      deleteData.append("fileId", id);
      deleteData.append("profileId", this.serviceProfile._id);
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.Please_confirm_delete"), {
          title: this.$t("messages.Please_Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("messages.YES"),
          cancelTitle: this.$t("messages.NO"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteFile(deleteData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t("messages.Deleted_successfully"),
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.serviceProfile.files = this.serviceProfile.files.filter(
                  (data) => data._id != id
                );
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.error.message,
                    icon: "CoffeeIcon",
                    variant: "error",
                  },
                });
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();

    // this.retreiveServiceProfile(this.profileId);

    axios.get(`profiles/${this.$route.params.id}`).then((response) => {
      if (response.data.data.userId._id == this.$store.state.auth.user._id) {
        this.serviceProfile = response.data.data;

        this.serviceProfile.profileIndustries = this.pluck(
          this.serviceProfile.profileIndustries,
          "id"
        );
        this.getServiceProfile();
      } else {
        window.location.href = "/error-404";
      }
    });

    // this.getServiceProfile();
  },
  // beforeUnmount() {
  //   console.log("asdsad");
  // },
  // unmounted() {
  //   console.log("asdsdasdsad");
  //   // this.getServiceProfile();
  // },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
